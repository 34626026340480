import "./App.css"
import lax from "lax.js/lib/lax.js"
import { Player } from "@lottiefiles/react-lottie-player"
import { useEffect, useRef } from "react"
import { FaArrowDown } from "react-icons/fa"
import { Howl } from "howler"

function App() {
  const confettiRef = useRef()
  const loveExplosionRef = useRef()
  const omgRef = useRef()

  useEffect(() => {
    var sound = new Howl({
      src: ["drumroll.mp3", "drumroll.wav"],
      autoplay: true,
      loop: true,
      volume: 0.5,
      onend: function () {
        console.log("Finished!")
      },
    })

    window.lax = { presets: lax.presets }
    lax.init()

    lax.addDriver("scrollY", function () {
      return window.scrollY
    })

    lax.addElements(".ax-scroll-hint", {
      scrollY: {
        opacity: [
          [0, "screenHeight*0.4"],
          [1, 0],
        ],
      },
    })

    lax.addElements(".ax-happy-birthday", {
      scrollY: {
        opacity: [
          ["screenHeight*0.82", "screenHeight*0.95"],
          [1, 0],
        ],
      },
    })

    // createLottieInteractivity({
    //   mode: "scroll",
    //   player: "#ax-lottie-confetti",
    //   actions: [
    //     {
    //       visibility: [0, 1],
    //       type: "seek",
    //       frames: [0, 165],
    //     },
    //   ],
    // })
    lax.addElements(".ax-lottie-confetti", {
      scrollY: {
        translateY: [
          ["screenHeight*0.65", "screenHeight*1"],
          [-1, 120],
          {
            cssFn: (val) => {
              confettiRef.current.setSeeker(+val)
              return 0
            },
          },
        ],
      },
    })

    lax.addElements(".ax-love-explosion", {
      scrollY: {
        translateY: [
          ["elInY+screenHeight*0.3", "elOutY"],
          [-1, 91],
          {
            cssFn: (val) => {
              loveExplosionRef.current.setSeeker(+val)
              return 0
            },
          },
        ],
      },
    })

    lax.addElements(".ax-omg", {
      scrollY: {
        translateY: [
          ["elInY+screenHeight*0.3", "elOutY"],
          [-1, 60],
          {
            cssFn: (val) => {
              omgRef.current.setSeeker(+val)
              return 0
            },
          },
        ],
      },
    })

    lax.addElements(".ax-logo", {
      scrollY: {
        translateY: [
          ["elInY-400", "elInY", "elInY+100"],
          [0, 0, 0],
        ],
        scale: [
          [
            "elInY-screenHeight*0.4",
            "elInY+screenHeight*0.5",
            "elInY+screenHeight*1",
            "elInY+screenHeight*2",
          ],
          [0.25, 1, 1, 10],
        ],
        opacity: [
          [0, "elInY+screenHeight*1.1", "elInY+screenHeight*1.4"],
          [1, 1, 0],
        ],
        rotate: [
          ["elInY+screenHeight", "elInY+screenHeight*1.4"],
          [0, 27],
        ],
      },
    })

    lax.addElements(".ax-lg-title", {
      scrollY: {
        translateX: [
          ["elInY+screenHeight*0.3", "elInY+screenHeight*0.8"],
          [-100, 0],
        ],
        opacity: [
          ["elInY+screenHeight*0.3", "elInY+screenHeight*0.8"],
          [0, 1],
        ],
      },
    })

    lax.addElements(".ax-feature", {
      scrollY: {
        translateX: [
          ["elInY+screenHeight*0.3", "elInY+screenHeight*0.6"],
          [-100, 0],
        ],
        opacity: [
          ["elInY+screenHeight*0.3", "elInY+screenHeight*0.6"],
          [0, 1],
        ],
      },
    })
  })

  return (
    <div className="App text-white text-center">
      <Player
        ref={confettiRef}
        src="animations/confetti.json"
        className="ax-lottie-confetti fixed inset-0"
        rendererSettings={{
          preserveAspectRatio: "none",
        }}
      ></Player>
      <section className="ax-alexa text-5xl font-bold absolute w-screen">
        <h1 className="sticky">Dear Alexa,</h1>
      </section>
      <section className="w-screen h-screen absolute">
        <div className="ax-scroll-hint absolute w-screen bottom-20 flex flex-col justify-end">
          <div className="uppercase font-bold text-xl m-5 mb-2">
            Scroll Down
          </div>
          <div className="mb-5 mx-auto">
            <FaArrowDown />
          </div>
        </div>
      </section>
      <section className="ax-happy-birthday absolute w-screen h-0 flex flex-col items-center justify-center">
        <h1 className="text-6xl font-bold uppercase tracking-widest mb-10">
          <span className="text-yellow-500">H</span>
          <span className="text-green-500">a</span>
          <span className="text-red-500">p</span>
          <span className="text-blue-500">p</span>
          <span className="text-purple-500">y</span>
        </h1>
        <h1 className="text-6xl font-bold uppercase tracking-widest">
          <span className="text-blue-500">B</span>
          <span className="text-red-500">i</span>
          <span className="text-purple-500">r</span>
          <span className="text-yellow-500">t</span>
          <span className="text-green-500">h</span>
          <span className="text-blue-500">d</span>
          <span className="text-green-500">a</span>
          <span className="text-red-500">y</span>
        </h1>
      </section>
      <section className="ax-letter w-screen">
        <article className="text-lg">
          <p className="mx-8" style={{ height: "80vh" }}>
            When we first met, I said I didn't like blockchain. So Tom asked a
            simple question: What kind of new tech <strong>do</strong> you like?
          </p>
          <p className="mx-8">
            I didn't have to think long and I said I was obsessed with light
            field displays.
          </p>
          <iframe
            src="https://giphy.com/embed/lr24wONU2paokQfICt"
            title="giphy-hologram"
            frameBorder="0"
            className="ax-hologram giphy-embed my-8 pointer-events-none"
            allowFullScreen
          ></iframe>
          <p className="m-8 mt-20">And then you were like:</p>
          <Player
            ref={omgRef}
            src="animations/omg.json"
            loop
            autoplay
            className="ax-omg"
          ></Player>
          <p className="m-8" style={{ height: "80vh" }}>
            "I am too!"
          </p>
          <p className="m-8">And the rest... is history!</p>
          <Player
            ref={loveExplosionRef}
            src="animations/love-explosion.json"
            className="ax-love-explosion w-screen"
          ></Player>
          <div style={{ height: "40vh" }}></div>
          <p className="m-8" style={{ height: "70vh" }}>
            So...
          </p>
          <p className="m-8" style={{ height: "70vh" }}>
            For your birthday, I decided to get you...
          </p>
          <iframe
            src="https://giphy.com/embed/FP56vNcwOVyvu"
            width="480"
            height="371"
            frameBorder="0"
            className="ax-drumroll giphy-embed my-8 pointer-events-none"
            allowFullScreen
          ></iframe>
        </article>
      </section>
      <section className="ax-logo-zoom w-screen">
        <div className="w-screen h-screen sticky top-0 flex flex-col items-center justify-center overflow-hidden">
          <img
            src="graphics/logo-looking-glass.svg"
            className="ax-logo object-contain"
          />
        </div>
      </section>
      <section className="ax-looking-glass w-screen">
        <article className="w-screen h-screen sticky top-0">
          <h1 className="ax-lg-title text-3xl font-bold py-12">
            Looking Glass 8K Gen2
          </h1>
          <video
            src="videos/looking-glass.mp4"
            className="w-screen"
            loop
            autoPlay
            muted
          />
          <ul className="ax-features list-none font-bold pt-5">
            <li className="ax-feature m-5">32" Holographic Display</li>
            <li className="ax-feature m-5">No 3D Glasses Required</li>
            <li className="ax-feature m-5">8K Native Resolution</li>
            <li className="ax-feature m-5">45-100 Views per Hogel</li>
            <li className="ax-feature m-5">53° Viewing Cone</li>
          </ul>
        </article>
      </section>
      <section className="w-screen h-screen flex flex-col items-center justify-center">
        <p className="p-5 text-2xl font-bold">Hope You Enjoy!</p>
        <p className="p-5 text-sm">By Stefan</p>
      </section>
    </div>
  )
}

export default App
